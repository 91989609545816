import React, {useEffect, useState} from 'react'
import {clsx} from "clsx"
import {IS_LIGHT_THEME, useWindowSize} from "@metronic/helpers/utils.jsx"
import {Col} from "reactstrap"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"

const Video = ({blockId}) => {
  const {tabData} = useSecuritiesContext()
  const [videoUrl, setVideoUrl] = useState('')
  const [width, setWidth] = useState('500')
  const [height, setHeight] = useState('300')
  const {width: windowWidth } = useWindowSize()

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      const url = tabData.find(block => block.uuid === blockId)?.video_url
      setVideoUrl(url ? url : '')
    }
  }, [tabData, blockId])

  useEffect(() => {
    if (IS_LIGHT_THEME) {
      if (windowWidth >= 1700) {
        setWidth('550')
        setHeight('350')
      } else if (windowWidth <= 1700 && windowWidth >= 400) {
        setWidth('400')
        setHeight('300')
      } else {
        setWidth('250')
        setHeight('200')
      }
    } else {
      if (windowWidth >= 1400) {
        setWidth('550')
        setHeight('350')
      } else if (windowWidth <= 1400 && windowWidth >= 1200) {
        setWidth('450')
        setHeight('350')
      } else if (windowWidth <= 1200 && windowWidth >= 992) {
        setWidth('380')
        setHeight('260')
      } else if (windowWidth <= 992 && windowWidth >= 543) {
        setWidth('450')
        setHeight('350')
      } else if (windowWidth <= 543 && windowWidth >= 400) {
        setWidth('350')
        setHeight('250')
      } else {
        setWidth('250')
        setHeight('200')
      }
    }

  }, [windowWidth])

  return (

    <Col className={clsx('d-flex justify-content-center align-items-center',
      IS_LIGHT_THEME ? 'col-12 col-xxl-6' : 'col-12 col-lg-6')}
    >
      <iframe
        width={width}
        height={height}
        src={videoUrl}
        title="" frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        className="rounded-4 mt-10"
      ></iframe>
    </Col>

  )
}

export default Video
