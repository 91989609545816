import React from 'react'
import {LogInContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const LogIn = (props) => {

  return (
    <>
      <Head title={'Login | ' + import.meta.env.VITE_APP_NAME} />
      <LogInContent />
    </>
  )
}

export default LogIn
