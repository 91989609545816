// Core
import React, {useEffect, useState} from "react"
import {Row, Col} from "reactstrap"
import clsx from "clsx"
import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import {useThemeMode} from "@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider"
// Widget
import {AdvancedRealTimeChart} from "react-ts-tradingview-widgets"
import {CryptoDepositWidget, CryptoCardWidget, AccountBalanceCards} from '@metronic/Components/CustomWidgets'
// Components
import { Toolbar } from '@metronic/_metronic/layout/components/toolbar/Toolbar'
import { Content } from '@metronic/_metronic/layout/components/Content'

const DashboardContent = () => {
  const {mode} = useThemeMode()
  const [chartKey, setChartKey] = useState(1)

  useEffect(() => {
    setChartKey(prevKey => prevKey + 1)
  }, [mode])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          {/* Crypto widgets BEGIN */}
          <Col className={clsx('', IS_LIGHT_THEME ? 'col-12 col-xxl-8' : 'col-12 col-xl-8')}>
            <CryptoCardWidget />
          </Col>
          {/* Crypto widget END */}

          {/* Deposit form BEGIN */}
          <Col className={clsx('', IS_LIGHT_THEME ? 'col-12 col-xxl-4' : 'col-12 col-xl-4')}>
            <CryptoDepositWidget />
          </Col>
          {/* Deposit form END */}
        </Row>

        {/* Account balance row BEGIN */}
        <AccountBalanceCards />
        {/* Account balance row END */}

        {/* Crypto chart row BEGIN */}
        <Row style={{height: "500px"}} className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col>
            <div className="h-100">
              <AdvancedRealTimeChart
                key={chartKey}
                container_id={'tradingview_widget_wrapper'}
                copyrightStyles={{parent: {display: 'none'}}}
                width={'100%'}
                height={'100%'}
                theme={mode === 'system' ? 'light' : mode}
                symbol="COINBASE:BTCUSD"
              />
            </div>
          </Col>
        </Row>
        {/* Crypto chart row END */}

      </Content>
    </>
  )
}

export default DashboardContent
