const InvoiceStatus = Object.freeze({
  PENDING: 'pending',
  PAID: 'paid',
  DECLINED: 'declined',
  PARTIAL: 'partial',
})

export {
  InvoiceStatus
}
