import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap"
import {clsx} from 'clsx'
import {router, useForm, usePage} from "@inertiajs/react"
import {selectListData} from "@metronic/api/general.js"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next";
import {assetFullName} from "@metronic/helpers/utils.jsx";

const CryptoDepositWidget = () => {
  const [assetOptions, setAssetOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [selectedAssetLabel, setSelectedAssetLabel] = useState('BTC')
  const [amountInUsd, setAmountInUsd] = useState('')
  const [amountInAsset, setAmountInAsset] = useState('')
  const [isUsdAmountInvalid, setIsUsdAmountInvalid] = useState(false)
  const [isAssetAmountInvalid, setIsAssetAmountInvalid] = useState(false)
  const [assets, setAssets] = useState([])
  const {t} = useTranslation()

  const {data, setData, post, reset} = useForm({
    asset_id: '',
    amount: '',
  })

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    if (!data.amount || data.amount <= 0) {
      setIsAssetAmountInvalid(true)
    }

    post('/deposit/crypto-deposit', {
      preserveScroll: true,
      onSuccess(r) {
        if (r.props?.success) {
          toast.success(r.props?.message)
          router.reload({only: ['account_balance', 'flash']})
          btn.disabled = false
        }
        reset()
        resetForm()
      }
    })

    btn.disabled = false
  }

  const amountInAssetChangeHandler = (e) => {
    setData('amount', Number(e.target.value))
    const newAmount = e.target.value
    setAmountInAsset(newAmount)
    calculateTotalUsdAmount(newAmount)
    setIsAssetAmountInvalid(false)
  }

  const amountInUsdChangeHandler = (e) => {
    const newAmountInUsd = e.target.value
    setAmountInUsd(newAmountInUsd)
    calculateAmount(newAmountInUsd)
    setIsUsdAmountInvalid(false)
  }

  const calculateTotalUsdAmount = (newAmount) => {
    assetOptions.forEach((option) => {
      if (option.value === Number(selectedAsset) && newAmount > 0) {
        const newTotalUsd = (newAmount * Number(option.price)).toFixed(2) * 1
        setAmountInUsd(newTotalUsd)
      } else if (newAmount <= 0) {
        setAmountInUsd('')
      }
    })
  }

  const calculateAmount = (newTotalUsdAmount) => {
    assetOptions.forEach((option) => {
      if (option.value === Number(selectedAsset) && newTotalUsdAmount > 0) {
        const newAmount = (newTotalUsdAmount / Number(option.price)).toFixed(8) * 1
        setAmountInAsset(newAmount)
      } else if (newTotalUsdAmount <= 0) {
        setAmountInAsset('')
      }
    })
  }

  const resetForm = () => {
    setAmountInAsset('')
    setAmountInUsd('')

    setData('asset_id', selectedAsset)
  }

  useEffect(() => {
    if (amountInAsset) {
      setData('amount', amountInAsset)
    }
  }, [amountInAsset, amountInUsd])

  useEffect(() => {
    selectListData('crypto_list').then(r => {
      setAssetOptions(r)
      setSelectedAsset(r[0].value)
    })
  }, [])

  useEffect(() => {
      setSelectedAssetLabel(assetOptions.find(i => i.value === Number(selectedAsset))?.label)
  }, [selectedAsset])

  useEffect(() => {
    calculateTotalUsdAmount(amountInAsset)
  }, [amountInAsset, selectedAsset])

  useEffect(() => {
    if (assetOptions && assetOptions?.length) {
      setData('asset_id', assetOptions[0].value)
    }
  }, [assetOptions]);

  return (
    <>
      <div className="card h-xl-100">
        <div
          className="card-header d-flex justify-content-center align-items-center min-h-50px p-0 border-bottom-2">
          <div className="nav-text fw-bold fs-2">{t('Crypto Deposit')}</div>
        </div>
        <div className="card-body d-flex align-items-lg-center">
          <form className="w-100" onSubmit={submitHandler}>
            <div className="tab-pane fade active show"
                 role="tabpanel" aria-labelledby="kt_forms_widget_1_tab_1">

              {/* Select START */}
              <div className="form-floating border border-gray-300 rounded mb-7">
                <select
                  className='form-select form-select-transparent bg-body'
                  name="asset_id"
                  aria-hidden="true"
                  onChange={(e) => {
                    setSelectedAsset(e.target.value)
                    setIsUsdAmountInvalid(false)
                    setIsAssetAmountInvalid(false)
                    setData('asset_id', e.target.value)
                  }}
                >
                  {(assetOptions && assetOptions.length) &&
                    assetOptions.map((asset, idx) => (
                      <option key={idx} value={asset.value}>
                        {assetFullName[asset.label]}/{asset.label}
                      </option>
                    ))
                  }
                </select>
                <label htmlFor="floatingInputValue">Select asset</label>
              </div>
              {/* Select END */}

              {/* Inputs row START */}
              <div className="row mb-7">
                <div className="col-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={clsx('form-control text-gray-800 fw-semibold', isAssetAmountInvalid && 'is-invalid')}
                      placeholder="00.0"
                      name="amount"
                      autoComplete='false'
                      value={amountInAsset}
                      onChange={(e) => amountInAssetChangeHandler(e)}
                    />
                    <label htmlFor="floatingInputValue">Amount({selectedAssetLabel})</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating">
                    <input
                      type="number"
                      className={clsx('form-control deposit-input text-gray-800 fw-semibold', isUsdAmountInvalid && 'is-invalid')}
                      placeholder="$00.0"
                      name="total_usd"
                      autoComplete='false'
                      min="50.00"
                      step="0.01"
                      value={amountInUsd}
                      onChange={(e) => amountInUsdChangeHandler(e)}
                    />
                    <label htmlFor="floatingInputValue">Amount(USD)</label>
                  </div>
                </div>
              </div>
              {/* Inputs row END */}

              {/* Submit button START */}
              <div className="d-flex align-items-end">
                <Button type='submit' color='primary' className='btn btn-primary fs-3 w-100'>Deposit</Button>
              </div>
              {/* Submit button END */}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CryptoDepositWidget
