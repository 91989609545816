// Core
import React, {useMemo, useState} from 'react'
import {Col, Row} from 'reactstrap'
//Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import ExchangeWidgetCard from '@metronic/Components/Exchange/ExchangeForm/ExchangeWidgetCard'
import {WalletDetailsTable} from "@metronic/Components/Tables"
import clsx from "clsx";
import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx";

const ExchangeContent = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const selectedRowsHandler = (row) => {setSelectedRows(row)}

  const columns = useMemo(
    () => [
    ], [])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          {/* Exchange form START */}
          <Col className={clsx('', IS_LIGHT_THEME ? 'col-xl-12 col-xxl-5' : 'col-md-12 col-lg-5')}>
            <ExchangeWidgetCard />
          </Col>
          {/* Exchange form END */}

          {/* Table START */}
          <Col className={clsx('', IS_LIGHT_THEME ? 'col-xxl-7' : 'col-md-12 col-lg-7')}>
            <WalletDetailsTable />
          </Col>
          {/* Table END */}
        </Row>
      </Content>
    </>
  )
}

export default ExchangeContent
