const P2POrderStatus = Object.freeze({
  PENDING: 10,
  WAITING_FOR_PAYMENT: 20,
  VERIFICATION: 30,
  EXECUTED: 40,
  DECLINED: 50,
})

const P2POrderStatusName = Object.freeze({
  PENDING: 'Pending',
  DECLINED: 'Declined',
  EXECUTED: 'Executed',
  WAITING_FOR_PAYMENT: 'Waiting for payment',
  VERIFICATION: 'Verification',
})

export {
  P2POrderStatus,
  P2POrderStatusName
}
