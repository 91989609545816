import React, {useEffect, useState} from 'react'
import ExchangeWidgetForm from "@metronic/Components/Exchange/ExchangeForm/ExchangeWidgetForm"
import {useTranslation} from "react-i18next"
import clsx from "clsx"
import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import {selectListData} from "@metronic/api/general.js"
import {getExchangeFee} from "@metronic/api/exchange.js"

const widgetStylesDefault = 'card-header card-header-stretch d-flex flex-column align-items-center'
const widgetStylesDefaultSm = 'flex-sm-row justify-content-sm-between'
const widgetStylesLight = 'flex-sm-row justify-content-sm-between flex-xxl-column justify-content-xxl-center'
const widgetStylesDefaultLg = 'd-lg-flex flex-lg-column justify-content-lg-center'
const widgetStylesDefaultXl = 'flex-xl-row justify-content-xl-between'
const cardToolbarDefaultHeight = 'h-auto h-sm-100 h-md-100 h-lg-auto h-xl-100'
const cardToolbarDefaultLight = 'h-auto h-sm-100 h-md-100 h-lg-auto h-xl-100 h-xxl-auto'

const ExchangeWidgetCard = () => {
  const [activeTab, setActiveTab] = useState('1')
  const {t} = useTranslation()
  const [assets, setAssets] = useState([])
  const [exchangeFee, setExchangeFee] = useState('')

  const formSent = () => {

    console.log('yes')
    // if (formSubmit) {
    //   formSubmit()
    // }
  }

  useEffect(() => {
    selectListData('assets_list').then(r => setAssets(r))
    getExchangeFee().then(r => setExchangeFee(r))
  }, [])

  return (
    <div className="card">
      <div className={clsx(widgetStylesDefault,
        IS_LIGHT_THEME ? widgetStylesLight :
          widgetStylesDefaultSm, widgetStylesDefaultLg, widgetStylesDefaultXl
      )}>
        <h3 className="card-title fw-bold text-gray-700">Exchange</h3>
        <div
          className={clsx('card-toolbar', IS_LIGHT_THEME ? cardToolbarDefaultLight : cardToolbarDefaultHeight)}>
          <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
            <li className="nav-item">
              <a className="nav-link active fw-bold text-gray-600" data-bs-toggle="tab" href="#kt_tab_pane_1">
                Locked Balance
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link fw-bold text-gray-600" data-bs-toggle="tab" href="#kt_tab_pane_2">
                Available Balance
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
            <ExchangeWidgetForm formSentHandler={formSent} assetsList={assets} feeValue={exchangeFee}/>
          </div>

          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <ExchangeWidgetForm formSentHandler={formSent} isReal={true} assetsList={assets} feeValue={exchangeFee}/>
          </div>
        </div>
      </div>
      <div className="mx-5 mb-5 p-3 rounded-3 text-center bg-light-primary">
        This exchange rate is guaranteed for 30 minutes after you generate an order. On rare occasions
        where it will take longer than 30 minutes for your payment to be cleared, we will update the exchange rate
        every 1 minute until payment has reached our account.
      </div>
    </div>
  )
}

export default ExchangeWidgetCard
