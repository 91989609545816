import React, {useContext, useEffect, useRef, useState} from 'react'
import {router, useForm, usePage} from "@inertiajs/react"
import {Button, Col, Input, Row, TabPane} from "reactstrap"
import {clsx} from "clsx"
import Cleave from "cleave.js/react"
import Select from "react-select"
// import PhoneInputComponent from "@metronic/Components/IntlTelInput/intlTelInput"
import {loadAnimation} from "lottie-web"
import {defineElement} from "lord-icon-element"
import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import {useFormik} from "formik"
import * as Yup from "yup"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"
import {selectListData} from "@metronic/api/general.js"
import {ModalContext} from "@metronic/Components/Modal/index.jsx";
import {TransactionsModalContext} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"
import {TransactionStatus} from "@metronic/Enums/Transactions/TransactionStatus.js"

defineElement(loadAnimation)

const DepositCreditCardForm = () => {
  const { openTransferDetailsModal } = useContext(TransactionsModalContext)
  const [activeTab, setActiveTab] = useState('')
  const [response, setResponse] = useState('')
  const countriesList = useRef([])
  const [country, setCountry] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const {openModal} = useContext(ModalContext)
  const { t } = useTranslation()
  const {props: pageProps} = usePage()
  const profileData = pageProps.auth.user.profile

  const {data, setData, post, processing, errors, reset, setDefaults} = useForm({
    deposit_amount: '',
    card_number: '',
    expiry_date: '',
    cvc: '',
    country: '',
    canton: '',
    address: '',
    postal_code: '',
    city: '',
    phone: '',
  })

  const formik = useFormik({
    initialValues: {
      deposit_amount: '',
      cvc: '',
      expiry_date: '',
      card_number: '',
      country: '',
      canton: '',
      address: '',
      postal_code: '',
      city: '',
      phone: ''
    },
    validationSchema: Yup.object({
      deposit_amount: Yup.number().required(''),
      cvc: Yup.string()
        .min(3, 'Invalid CVC')
        .required(''),
      expiry_date: Yup.string()
        .min(5, 'Invalid Expiry Date')
        .required(''),
      card_number: Yup.string()
        .min(19, 'Invalid Card Number')
        .required(''),
      country: Yup.string().required(''),
      canton: Yup.string().required(''),
      address: Yup.string().required(''),
      postal_code: Yup.string().required(''),
      city: Yup.string().required(''),
      phone: Yup.string().required(''),
    }),
    onSubmit: () => {
      setIsButtonDisabled(true)

      post('/deposit/credit-card-deposit', {
        preserveScroll: true,
        onSuccess(r) {
          if (r.props?.success) {
            toast.success(r.props?.message)
            router.reload({only: ['account_balance', 'flash']})
            setIsButtonDisabled(false)
            window.scrollTo(0, 0)
          }
          reset()
          resetFormik()
        },
        onError(r) {
          if (r.message === 'Deposit declined!') {
            setActiveTab('kt_tab_pane_4')
          }
        }
      })

      setIsButtonDisabled(false)
    }
  })

  const statusIcons = {
    pending:
      <lord-icon
        src="https://cdn.lordicon.com/lupuorrc.json"
        trigger="loop"
        colors="primary:#0ab39c,secondary:#405189"
        style={{width: "120px", height: "120px"}}
      >
      </lord-icon>,
    executed:
      <lord-icon
        src="https://cdn.lordicon.com/lupuorrc.json"
        trigger="loop"
        colors="primary:#0ab39c,secondary:#405189"
        style={{width: "120px", height: "120px"}}
      >
      </lord-icon>,
    declined:
      <div className="symbol symbol-125px">
        <div className="symbol-label fs-2 fw-bold rounded-circle bg-light-danger">
          <i className="bi bi-x text-danger mt-1" style={{fontSize: '150px'}}></i>
        </div>
      </div>
  }

  const wizardLiItem = (href, name, desc, number, dashLine = true) => {
    return (
      <>
        <li className="d-flex align-items-center cursor-pointer nav-item w-100 border-0">
          <a
            className={clsx('nav-link p-0 align-items-center w-100 border-0',
              IS_LIGHT_THEME ? 'd-flex flex-column flex-xxl-row' : 'd-flex flex-column flex-xl-row',
              activeTab === href ? 'active' : '')}
            onClick={() => toggleTab(href)}
            data-bs-toggle="tab"
          >
            <div className={clsx("symbol symbol-40px position-relative",
              IS_LIGHT_THEME ? 'pb-3 pb-xxl-0' : 'pb-3 pb-xl-0'
            )}>
              <div className={clsx('symbol-label fs-2 fw-bold',
                activeTab === href ? 'bg-primary text-inverse-primary' : 'bg-light-primary text-primary '
              )}>
                {number}
              </div>
            </div>
            <div className={clsx(
              'w-100 d-flex justify-content-between flex-column align-items-center',
              IS_LIGHT_THEME ? 'ms-0 ms-xxl-5 flex-xxl-row ' : 'ms-0 ms-xl-5 flex-xl-row'
            )}>
              <div className={clsx('d-flex flex-column align-items-center',
                IS_LIGHT_THEME ? 'align-items-xxl-start' : 'align-items-xl-start'
                )}>
                <div className={clsx(
                  'fw-bold fs-4 fs-sm-3 d-flex', activeTab === href ? 'text-primary' : 'text-gray-600'
                )}>
                  {name}
                </div>
                <div className="text-gray-500 d-none d-sm-block fw-bold fs-8 text-truncate w-100">
                  {desc}
                </div>
              </div>
              {activeTab === href &&
                <div className={clsx('pt-1 me-0', IS_LIGHT_THEME ? 'd-none d-xxl-block' : 'd-none d-xl-block')}>
                  <i className='ki-outline ki-double-right fs-2 text-primary'
                  ></i>
                </div>}
            </div>
          </a>
        </li>
        {dashLine &&
          <div
            style={{marginLeft: '20px'}}
            className={clsx('nav-item w-100 border-start border-start-dashed border-primary h-50px',
              IS_LIGHT_THEME ? 'd-none d-xxl-block' :
            'd-none d-xl-block'
            )}
          ></div>}
      </>
    )
  }

  useEffect(() => {
    setActiveTab('kt_tab_pane_1')
  }, [])

  const resetFormik = () => {
    formik.values.deposit_amount = '',
    formik.values.cvc = '',
    formik.values.expiry_date = '',
    formik.values.card_number = '',
    formik.values.country = '',
    formik.values.canton = '',
    formik.values.address = '',
    formik.values.postal_code = '',
    formik.values.city = '',
    formik.values.phone = ''
  }

  useEffect(() => {
    if (profileData && countriesList.current.length) {
      const r = profileData

      setData(data => ({...data,
        address: profileData.address,
        phone: profileData.phone,
        canton: profileData.state,
        postal_code: profileData.postal_code,
        city: profileData.city,
        country: profileData.country
      }))

      formik.setFieldValue('phone', !r.phone ? '' : r.phone)
      formik.setFieldValue('canton', !r.state ? '' : r.state)
      formik.setFieldValue('address', !r.address ? '' : r.address)
      formik.setFieldValue('postal_code', !r.postal_code ? '' : r.postal_code)
      formik.setFieldValue('city', !r.city ? '' : r.city)
      setCountry(countriesList.current.find(i => i.value === r.country))
      formik.setFieldValue('country', !r.country ? '' : r.country)
    }
  }, [profileData, countriesList.current])

  const getCountriesData = async () => {
    countriesList.current = await selectListData('countries')
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {

      if (tab === 'kt_tab_pane_2') {
        if (Number(data.deposit_amount) > 0) {
          setActiveTab(tab)
        } else {
          toast.error(t('Please enter Deposit Amount'))
        }
      } else if (tab === 'kt_tab_pane_3') {
        if (formik.values.card_number &&
          formik.values.expiry_date &&
          formik.values.cvc &&
          formik.values.cvc.length === 3 &&
          formik.values.expiry_date.length === 5 &&
          formik.values.card_number.length === 19
        ) {
          setActiveTab(tab)
        } else if (!formik.values.card_number || formik.values.card_number.length < 19) {
          toast.error(t('Please enter a valid Card Number (16 digits)'))
        } else if (!formik.values.expiry_date || formik.values.expiry_date.length < 5) {
          toast.error(t('Please enter a valid Expiry Date (MM/YY)'))
        } else if (!formik.values.cvc || formik.values.cvc.length < 3) {
          toast.error(t('Please enter a valid CVC (3 digits)'))
        }
      } else {
        setActiveTab(tab)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      await getCountriesData()
      // getProfileData()
    })()
  }, [])

  return (
    <>
      <Col className={clsx(IS_LIGHT_THEME ? 'col-12 col-xxl-4' : 'col-12 col-xl-4', activeTab === 'kt_tab_pane_4' && 'd-none')}>
        <div className="card card-p-0 d-flex justify-content-center justify-content-xl-start flex-row-auto w-100">
          <div className="card-body px-3 py-5 py-sm-10 py-xl-20">
            <ul className="nav nav-tabs px-3 px-xl-10 border-0 d-flex">
              <Col className={clsx(IS_LIGHT_THEME ? 'col-4 col-xxl-12' : 'col-4 col-xl-12')}>
                {wizardLiItem('kt_tab_pane_1', 'Amount', 'Enter the amount of deposit',  1)}
              </Col>
              <Col className={clsx(IS_LIGHT_THEME ? 'col-4 col-xxl-12' : 'col-4 col-xl-12')}>
                {wizardLiItem('kt_tab_pane_2', 'Card info', 'Set up card information', 2)}
              </Col>
              <Col className={clsx(IS_LIGHT_THEME ? 'col-4 col-xxl-12' : 'col-4 col-xl-12')}>
                {wizardLiItem('kt_tab_pane_3', 'Billing info', 'Set up billing information', 3, false)}
              </Col>
            </ul>
          </div>
        </div>
      </Col>

      <Col className={clsx(
        '', activeTab === 'kt_tab_pane_4' ?
        'col-12 d-flex justify-content-center' :
          IS_LIGHT_THEME ? 'col-12 col-xxl-8' : 'col-12 col-xl-8'
      )}>
        <Col className={clsx('h-100', activeTab === 'kt_tab_pane_4' ? 'col-12 col-sm-8' : 'col-12' )}>
          <div className="card h-100 bg-body">
            <div className="card-header flex-nowrap card-p-0">
              <h3 className="card-title d-flex justify-content-center w-100">
                <div className="card-label fw-bold fs-3 text-gray-700">
                  {/*{(activeTab === 'kt_tab_pane_3') ?*/}
                  {/*  response?.message : 'Enter payment information'*/}
                  {/*}*/}
                  {activeTab === 'kt_tab_pane_4' ? 'Deposit declined!' : 'Enter payment information'}
                </div>
              </h3>
            </div>
            <div className="card-body d-flex flex-column align-content-center justify-content-center">
              <form onSubmit={e => {
                e.preventDefault()

                if (!formik.values.country) {
                  toast.error(t('Please select a country'))
                } else if (!formik.values.canton) {
                  toast.error(t('Please enter Canton'))
                } else if (!formik.values.address) {
                  toast.error(t('Please enter Address'))
                } else if (!formik.values.postal_code) {
                  toast.error(t('Please enter Postal Code'))
                } else if (!formik.values.city) {
                  toast.error(t('Please enter City'))
                } else if (!formik.values.phone) {
                  toast.error(t('Please enter Phone Number'))
                } else {
                  formik.handleSubmit(e)
                }
              }}>
                <div className="tab-content" id="myTabContent">

                  {/* First tab content START */}
                  <div
                    className={clsx('tab-pane fade', activeTab === 'kt_tab_pane_1' ? 'active show' : '')}
                    id="kt_tab_pane_1"
                    role="tabpanel"
                  >
                    <div className="form-group">
                      <label className="ps-2 pb-3 text-muted">
                        Enter the amount in USD<sup className="text-danger fs-12">*</sup>
                      </label>
                      <Input
                        type="text"
                        className={clsx('form-control text-gray-800 fw-semibold')}
                        placeholder="00.0"
                        name="deposit_amount"
                        autoComplete='off'
                        value={data.deposit_amount}
                        onChange={(e) => {
                          setData('deposit_amount', e.target.value)
                          formik.setFieldValue('deposit_amount', e.target.value)
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-center pt-10">
                      <Button
                        type="button"
                        className="btn w-50 btn-sm btn-light-success hover-scale border border-dashed border-success"
                        onClick={() => toggleTab('kt_tab_pane_2')}
                      >
                        NEXT <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                      </Button>
                    </div>

                  </div>
                  {/* First tab content END */}

                  {/* Second tab content START */}
                  <div
                    className={clsx('tab-pane fade', activeTab === 'kt_tab_pane_2' ? 'active show' : '')}
                    id="kt_tab_pane_2"
                    role="tabpanel"
                  >
                    <Row>
                      <div className="col-12 mb-3">
                      <span className="text-muted">
                        Card info
                        <sup className="text-danger fs-12">*</sup>
                      </span>
                      </div>

                      <div className="col-12 mb-6 input-group">
                      <span className="input-group-text">
                        <i className="bi bi-credit-card fs-2 text-muted"></i>
                      </span>
                        <Cleave
                          placeholder="Card number"
                          autoComplete="off"
                          name="card_number"
                          options={{
                            creditCard: true,
                          }}
                          value={data.card_number}
                          onChange={(e) => {
                            formik.setFieldValue('card_number', e.target.value)
                            setData('card_number', e.target.value)
                          }}
                          className="form-control"
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <div className="input-group">
                        <span className="input-group-text">
                          <i className="bi bi-calendar3 fs-2 text-muted"></i>
                        </span>
                          <Cleave
                            name="expiry_date"
                            placeholder="MM/YY"
                            options={{
                              date: true,
                              delimiter: "/",
                              datePattern: ['m', 'y']
                            }}
                            value={data.expiry_date}
                            autoComplete="off"
                            onChange={(e) => {
                              setData('expiry_date', e.target.value)
                              formik.setFieldValue('expiry_date', e.target.value)
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-6 mb-3">
                        <div className="input-group">
                        <span className="input-group-text">
                          <i className="bi bi-lock fs-2 text-muted"></i>
                        </span>
                          <Input
                            type="text"
                            name="cvc"
                            className="form-control rounded-end"
                            maxLength="3"
                            placeholder="CVC"
                            autoComplete="off"
                            value={data.cvc}
                            onChange={e => {
                              formik.setFieldValue('cvc', e.target.value)
                              setData('cvc', e.target.value)
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex gap-3 pt-10 justify-content-between">
                        <Button
                          type="button"
                          color="primary"
                          className="btn btn-sm btn-light-primary border border-dashed border-primary w-50 w-sm-25 fs-16 hover-scale"
                          onClick={() => toggleTab('kt_tab_pane_1')}
                        >
                          <i className="ki-outline ki-double-left fs-2 pb-1"></i> PREVIOUS
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-sm btn-light-success border border-dashed border-success w-50 w-sm-25 fs-16 hover-scale"
                          onClick={() => toggleTab('kt_tab_pane_3')}
                        >
                          NEXT <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                        </Button>
                      </div>
                    </Row>

                  </div>
                  {/* Second tab content END */}

                  {/* Third tab content START */}
                  <div
                    className={clsx('tab-pane fade', activeTab === 'kt_tab_pane_3' ? 'active show' : '')}
                    id="kt_tab_pane_3"
                    role="tabpanel"
                  >

                    <Row>
                      <div className="col-12 mb-3">
                        <span className="text-muted">Billing info</span>
                      </div>
                      <div className="col-12 mb-3 input-group">
                        <div className="w-100">
                          <label htmlFor="country" className="form-label">
                            Country
                            <sup className="text-danger fs-12">*</sup>
                          </label>
                          <Select
                            name="country"
                            id="country"
                            options={countriesList.current}
                            value={country}
                            onChange={(selectedOptions) => {
                              setCountry(selectedOptions)
                              formik.setFieldValue('country', selectedOptions.value)
                              setData('country', selectedOptions.value)
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <div className="col-sm-12 w-100">
                          <label htmlFor="canton" className="form-label">
                            Canton
                            <sup className="text-danger fs-12">*</sup>
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="canton"
                            placeholder='Canton'
                            name="canton"
                            autoComplete="off"
                            value={data.canton}
                            onChange={(e) => {
                              formik.setFieldValue('canton', e.target.value)
                              setData('canton', e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <div className="col-sm-12 w-100">
                          <label htmlFor="address" className="form-label">
                            Address
                            <sup className="text-danger fs-12">*</sup>
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address"
                            placeholder='Address'
                            name="address"
                            value={data.address}
                            onChange={(e) => {
                              formik.setFieldValue('address', e.target.value)
                              setData('address', e.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6  mb-3">
                        <label htmlFor="postal_code" className="form-label">
                          Postal/ZIP code
                          <sup className="text-danger fs-12">*</sup>
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder='Postal/ZIP code'
                          name="postal_code"
                          value={data.postal_code}
                          onChange={(e) => {
                            formik.setFieldValue('postal_code', e.target.value)
                            setData('postal_code', e.target.value)
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 mb-3">
                        <label htmlFor="city" className="form-label">
                          City
                          <sup className="text-danger fs-12">*</sup>
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder='City'
                          name="city"
                          value={data.city}
                          onChange={(e) => {
                            formik.setFieldValue('city', e.target.value)
                            setData('city', e.target.value)
                          }}
                        />
                      </div>
                      <div className="mb-3 col-6">
                        <label htmlFor="phone" className="form-label">
                          Phone
                          <sup className="text-danger fs-12">*</sup>
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder='Phone'
                          name="full_phone"
                          value={data.phone}
                          onChange={(e) => {
                            formik.setFieldValue('phone', e.target.value)
                            setData('phone', e.target.value)
                          }}
                        />
                        {/*<div className="input-group intel-width-100 col-6">*/}
                          {/*<PhoneInputComponent*/}
                          {/*  phone={formik.values.phone}*/}
                          {/*  onChange={(newValue) =>*/}
                          {/*    formik.setFieldValue('phone', newValue)*/}
                          {/*  }*/}
                          {/*/>*/}
                        {/*</div>*/}
                      </div>

                      <div className="d-flex gap-3 pt-10 justify-content-between">
                        <Button
                          type="button"
                          color="primary"
                          className="btn btn-sm btn-light-primary border border-dashed border-primary w-50 w-sm-25 fs-16 hover-scale"
                          onClick={() => toggleTab('kt_tab_pane_2')}
                        >
                          <i className="ki-outline ki-double-left fs-2 pb-1"></i> PREVIOUS
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-sm btn-light-success border border-dashed border-success w-50 w-sm-25 fs-16 hover-scale"
                          // onClick={() => toggleTab('kt_tab_pane_4')}
                          disabled={isButtonDisabled}
                        >
                          SUBMIT <i className="ki-outline ki-double-right fs-2 pb-1"></i>
                        </Button>
                      </div>
                    </Row>
                  </div>
                  {/* Third tab content END */}

                  {/* Fourth tab content START */}
                  <div
                    className={clsx('tab-pane fade', activeTab === 'kt_tab_pane_4' ? 'active show' : '')}
                    id="kt_tab_pane_4"
                    role="tabpanel"
                  >
                    {/* TODO: needs refactoring. Information should be retrieved from the back-end. */}
                    <TabPane tabId={4}>
                      <div>
                        <div className="text-center">
                          <div className="mb-4">
                            {statusIcons[TransactionStatus.DECLINED]}
                            {/*{statusIcons['executed']}*/}
                          </div>
                          <h4>Deposit declined!</h4>
                          <span className="fs-5 text-gray-600">
                            Your request has been declined. This type of deposit is currently unavailable in your country.
                            <br /> Please contact support for additional information
                          </span>
                          {/*<h4>{t(`${response.title}`)}</h4>*/}
                          {/*<span dangerouslySetInnerHTML={{__html: t(`${response.text}`)}}></span>*/}
                        </div>
                      </div>
                      <div className="d-flex justify-content-center pt-10">
                        <Button
                          type="button"
                          className="btn w-75 w-sm-50 btn-sm btn-light-success hover-scale border border-dashed border-success"
                          onClick={() => setActiveTab('kt_tab_pane_1')}
                        >
                          NEW DEPOSIT <i className="ki-outline fs-2 pb-1"></i>
                        </Button>
                      </div>
                    </TabPane>
                  </div>
                  {/* Fourth tab content END */}

                </div>
              </form>
            </div>
          </div>
        </Col>
      </Col>
    </>
  )
}

export default DepositCreditCardForm
