import React from 'react'
import {Col, Row} from "reactstrap"
// Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import TwoFaForm from "@metronic/Components/Profile/TwoFaForm"
// Profile Header
import ProfileHeader from "@metronic/Components/Profile/ProfileHeader"
// Tabs
import OverviewTab from "@metronic/Components/Profile/Tabs/OverviewTab"
import SettingsTab from "@metronic/Components/Profile/Tabs/SettingsTab"
import ChangePasswordTab from "@metronic/Components/Profile/Tabs/ChangePasswordTab";
import DocumentsTab from "@metronic/Components/Profile/Tabs/DocumentsTab";
// Context
import {useTabContext} from "@metronic/Components/Profile/TabContext/TabContext"
// import {Link} from "@inertiajs/react";

import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import clsx from "clsx";

const ProfileContent = () => {
  const {activeTab} = useTabContext()

  return (
    <>
      {/*<Link href={route('transactions')}>Transactions</Link>*/}
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <ProfileHeader/>
          </Col>
        </Row>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          {/*Overview Tab*/}
          {activeTab === 'overview' &&
            <>
              <Col
                className={clsx(IS_LIGHT_THEME ? 'col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4' : 'col-12 col-lg-6 col-xl-4')}>
                <TwoFaForm/>
              </Col>
              <Col
                className={clsx(IS_LIGHT_THEME ? 'col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-8' : 'col-12 col-lg-6 col-xl-8')}>
                <OverviewTab/>
              </Col>
            </>
          }

          {/*Settings Tab*/}
          {activeTab === 'personal_information' &&
            <Col className="col-12">
              <SettingsTab/>
            </Col>
          }

          {/*Change Password Tab*/}
          {activeTab === 'change_password' &&
            <Col className="col-12">
              <ChangePasswordTab/>
            </Col>
          }

          {/*Documents Tab*/}
          {activeTab === 'documents' &&
            <Col className="col-12">
              <DocumentsTab/>
            </Col>
          }
        </Row>
      </Content>
    </>
  )
}

export default ProfileContent
