import React, {useContext, useMemo, useRef, useState} from "react"
import BaseTable from "../../Tables/BaseTable.jsx"
import {Card, CardBody, Col, Row} from "reactstrap"
import {cryptoIconsMapper, isTwoPrecision, prettyDate, TransactionStatusBadge} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {ConfirmationInformationCard, SaleConfirmationCard} from "@metronic/Components/P2P/Components/index.jsx"
import OrderDetailsModal from "@metronic/Components/P2P/Components/OrderDetailsModal.jsx";
import {P2POrderStatus} from "@metronic/Enums/P2POrders/P2POrderStatus.js";

const P2POrdersTable = ({handleOrderSelect}) => {
  const baseTableMethodsRef = useRef(null)
  const [selectedRows, setSelectedRows] = useState([])
  const {openModal} = useContext(ModalContext)

  const selectedRowsHandler = (row) => {
    setSelectedRows(row)
  }

  const openOrderDetailsHandler = (row) => {
    if (row.status === P2POrderStatus.PENDING) {
      openModal({
        title: "",
        content: <ConfirmationInformationCard order={row}/>,
        size: "xl",
      })
    } else if (row.status === P2POrderStatus.EXECUTED || row.status === P2POrderStatus.DECLINED) {
      openModal({
        title: "Order details",
        content: <OrderDetailsModal order={row}/>,
      })
    } else {
      openModal({
        title: "",
        content: <SaleConfirmationCard order={row} status={row.status}/>,
        size: "xl",
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='fw-bold fs-5'>ID</span>,
        selector: row => <a onClick={() => {
          openOrderDetailsHandler(row)
          handleOrderSelect(row)
        }}
          className="fw-bold cursor-pointer">#{row.id}</a>,
        sortable: true,
        width: '90px'
      },
      {
        id: "transfer_id",
        name: <span className='fw-bold fs-5 text-nowrap'>Transaction ID</span>,
        selector: row => <span className="fw-bold">{row.transfer_id ? `#${row.transfer_id}` : "-"}</span>,
        sortable: true,
        omit: false,
      },
      {
        id: "sell_asset",
        name: <span className="fw-bold fs-5 text-nowrap">Sell Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="h-25px w-25px rounded-circle me-2">
              <img
                src={cryptoIconsMapper[row.sell_asset.currency.code]}
                className="img-fluid"
                alt={row.sell_asset.currency.name}
              />
            </div>
            <span className="fw-bold">{row.sell_asset.currency.code}</span>
          </div>
        ),
        reorder: true,
        omit: false
      },
      {
        id: "sell_price",
        name: <span className='fw-bold fs-5 text-nowrap text-truncate'>Sell Amount</span>,
        selector: row => <span
          className="fw-bold">{isTwoPrecision(row.sell_asset.currency.code, row.sell_price)}</span>,
        sortable: true,
        omit: false,
      },
      {
        id: "buy_asset",
        name: <span className="fw-bold fs-5 text-nowrap">Buy Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="h-25px w-25px rounded-circle me-2">
              <img
                src={cryptoIconsMapper[row.buy_asset.currency.code]}
                className="img-fluid"
                alt={row.buy_asset.currency.name}
              />
            </div>
            <span className="fw-bold">{row.buy_asset.currency.code}</span>
          </div>
        ),
        reorder: true,
        omit: false
      },
      {
        id: "buy_price",
        name: <span className='fw-bold fs-5 text-nowrap text-truncate'>Buy Amount</span>,
        selector: row => <span className="fw-bold">{isTwoPrecision(row.buy_asset.currency.code, row.buy_price)}</span>,
        sortable: true,
        omit: false,
      },
      {
        id: "created_at",
        name: <span className='fw-bold fs-5 text-nowrap'>Created at</span>,
        selector: row => <span className="fw-bold">{prettyDate(row.created_at)}</span>,
        sortable: true,
        omit: false,
      },
      {
        id: "status",
        name: <span className='fw-bold fs-5'>Status</span>,
        selector: row => <span className="fw-bold">
          <TransactionStatusBadge status={row.order_status} color={row.order_color} />
        </span>,
        sortable: true,
        omit: false,
      },
    ], [])

  return (
    <>
      <Row>
        <Col className="col-12">
          <BaseTable
            columns={columns}
            title={'P2P Orders'}
            selectableRows={false}
            methodsRef={baseTableMethodsRef}
            selectedRowsHandler={selectedRowsHandler}
          />
        </Col>
      </Row>
    </>
  )
}

export default P2POrdersTable
