import React, {useEffect, useState} from 'react'
import {clsx} from "clsx"
import {IS_LIGHT_THEME} from "@metronic/helpers/utils.jsx"
import {Col} from "reactstrap"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {blockCardTitle} from "@metronic/Components/Securities/Components.jsx"

const Descriptions = ({blockId}) => {
  const {tabData} = useSecuritiesContext()
  const [isVideo, setIsVideo] = useState(false)
  const [blockData, setBlockData] = useState({})

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      const block = tabData.find(block => block.uuid === blockId)
      setBlockData(block || null)
      setIsVideo(block.video_url)
    }
  }, [tabData, blockId])

  return (
    <>
      {blockData ?
        <Col className={clsx('',
          IS_LIGHT_THEME ? (isVideo ? 'col-12 col-xxl-6' : 'col-12') :
            isVideo ? 'col-12 col-lg-6' : 'col-12'
        )}>
          {blockCardTitle(blockData?.title)}

          <p style={{textAlign: 'justify'}} className="fs-5 fw-bold text-gray-700">
            {
              blockData?.text ? blockData?.text.split(/\r?\n/)
              .map((string, idx) => (
                <React.Fragment key={idx}>
                  <span>{string}</span> <br/>
                </React.Fragment>
              )) : null
            }
          </p>
        </Col> : null}
    </>
  )
}

export default Descriptions
